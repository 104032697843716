<template>
   <MobileHeader :mobileHeaderTitle="'NEWS'"></MobileHeader>
  <div class="container has-space has-header" ref='scrollComponent' id="gg">
      <div class="news__categories-wrapper">
        <div class="news_latest-news__title-wrapper">
            <span v-if="isCategoriesMode" class="news_latest-news__title">{{categoryName}}</span>
            <div v-else >
                <span>{{$t('ARTICLES_RELATED_TO')}}</span><span class="news_latest-news__title"> '{{categoryName}}'</span>
            </div>
        </div>

        <div class="news_latest-news__wrapper" v-if="categoriesNewsList.length>0">
           <NewCategoriesCard :data="categoriesNewsList"> </NewCategoriesCard>
        </div>
        <div class="no-data" v-else>{{$t('NO_DATA')}}</div>
      </div>
  </div>
</template>
<script>
import config from '@/js/config.js'
import moment from 'moment'
import MobileHeader from '@/components/mobile/Header.vue'
import NewCategoriesCard from '@/components/news/NewCategoriesCard.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
		MobileHeader,
        NewCategoriesCard     
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            categoriesNewsList: [], 
            isCategoriesMode: true,
            page: {
                pageNo: 1,
                pageSize: 30,
                totalPage: 1
            },
            isFirstTime: false,
            isLoadMore: false,
            apiReturned: false,
            newSportType: null,
        }
    },
    watch:{
		$route() {
            if (this.$route.name === 'newsCategories' || this.$route.name === 'newsRelated') {
                this.init();
            } 
        },
	},
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    created() {
        this.categoryId = this.$route.params.categoryId; 
        this.categoryName = this.$route.params.categoryName.replace(/-/g, ' ');;  
        
        if (this.$route.name === 'newsRelated') {
            this.isCategoriesMode = false;
        } else {
            this.isCategoriesMode = true;
        }
        this.setNewsSportType();
    },
    computed:{
        ...mapGetters([              
                'isMobile',
                'currentLanguageObj',
                'currentSportType'
           ]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions([
            "getNewsListByCategory",
            "getNewsListByTag",
            "currentSportTypeParam"
        ]),
        
        setNewsSportType() {
            console.log(this.$route)
            let pathSportType = this.$route.query.sportType;
            if (pathSportType == "football") {
                this.newsSportType = "SOCCER";
            } else {
                this.newsSportType = (pathSportType || "").toUpperCase();
            }
        },

        async getNewsListByCategoryData(isFirstTime){
            let params = {
                categoryId: this.categoryId,
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize
            }
           
            const result = await this.getNewsListByCategory(params);
            // YIM - flag for only load new page after last page returned and scrolled to end of page
            this.apiReturned = true;
            this.page.totalPage = result.result.totalPages;

             result.result.content.forEach(x=>{
                 x.urlName = x.title.toLowerCase().replace(/\s/g, '-')
                 x.publishedOn = moment(x.publishedOn).fromNow();

                if (x.sportType == "SOCCER") {
                    x.sportTypePath = "football";
                } else {
                    x.sportTypePath = x.sportType.toLowerCase();
                }
             })

            if(this.isLoadMore) {
                result.result.content.forEach(x=>{
                    this.categoriesNewsList.push(x);
                })
            } else {
                this.categoriesNewsList = result.result.content;
            }
            if (isFirstTime) {
                 window.addEventListener('scroll', this.handleScroll) 
            }
        },

        async getNewsListByTagData(isFirstTime){
        
            let params = {
                language: this.$route.query.language ? this.$route.query.language : this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
                tagIds: this.categoryId,
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                sportType: this.newsSportType
            }

            const result = await this.getNewsListByTag(params);
            // YIM - flag for only load new page after last page returned and scrolled to end of page
            this.apiReturned = true;
            this.page.totalPage = result.result.totalPages;

              result.result.content.forEach(x=>{
                 x.urlName = x.title.toLowerCase().replace(/\s/g, '-')
                 x.publishedOn = moment(x.publishedOn).fromNow();

                if (x.sportType == "SOCCER") {
                    x.sportTypePath = "football";
                } else {
                    x.sportTypePath = x.sportType.toLowerCase();
                }
             })

            if(this.isLoadMore) {
                result.result.content.forEach(x=>{
                    this.categoriesNewsList.push(x);
                })
            } else {
                this.categoriesNewsList = result.result.content;
            }

            if (isFirstTime) {
                 window.addEventListener('scroll', this.handleScroll) 
            }

        },

        handleScroll(){
            let element = this.$refs.scrollComponent;
            if (element.getBoundingClientRect().bottom < window.innerHeight && this.apiReturned) {
                this.apiReturned = false;
                this.loadMorePosts()
            }
        },
        
        loadMorePosts(){      
            this.page.pageNo++;
            this.isLoadMore = true;

            if (this.page.totalPage >= this.page.pageNo) {
                if (this.isCategoriesMode) {
                    this.getNewsListByCategoryData();
                } else {
                    this.getNewsListByTagData();
                }
            }         
        },
        init(){
            console.log(this.isCategoriesMode)
            if (this.isCategoriesMode) {
                this.getNewsListByCategoryData(true);

            } else {
                this.getNewsListByTagData(true);
            }
        }
    },
}
</script>

<style scoped>
.news__categories-wrapper{
    width: 66%;
    margin: 0 auto;
}

.news_latest-news__title-wrapper{
	display: flex;
	justify-content: space-between;
	border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.news_latest-news__title{
	font-size: 0.875rem;
	font-weight: 700;
    text-transform: capitalize;
}

.news_latest-news__container{
	display: flex;
	padding-bottom: 1rem;
	border-bottom: .1rem solid var(--color-grey-60);
		
}
.news_latest-news__container-img{
	margin-right: 1.5rem;
	position: relative;
    width: 13.31rem;
    height: 7.5rem;
}
.news_latest-news__container-img img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.news_latest-news__container-tag__wrapper{
    margin: .3rem 0;
}
.news_latest-news__container-tag{
    display: inline-block;
    background-color: var(--color-grey-60);
    padding: .4rem .5rem;
    font-size: 0.6875rem;
    border-radius: .4rem;
    margin-right: .5rem;
}
/* 
.news_latest-news__wrapper{
	display: grid;
    row-gap: 1rem
}
.news_latest-news__container-desc{
	padding: 1rem 1rem 1rem 0;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32rem;
}
.news_latest-news__container-desc__title{
    font-weight: 700;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.news_latest-news__container-desc_tag{
	font-size: 0.6rem;
}
.news_latest-news__container-desc_tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news_latest-news__container-desc_tag:last-of-type::after {
	display: none;
} */
@media (max-width: 768px){
    .news__categories-wrapper{
        width: auto;
        margin: 0;
    }
    .news_latest-news__title-wrapper{
        margin-top: 1rem;
        border-bottom: none;
    }
}

</style>